import React, { useState, useEffect } from "react";
import { Container, Form, Button, Spinner, Modal } from "react-bootstrap";
import { getFirestore, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { SnackbarProvider, useSnackbar } from "notistack";
import axios from 'axios';
import cyborgImage from "./Atendente.png";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import ModalGenerico from "../modalGenerico/modal";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Formulario = (id) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sessao = id.id.sessao
  const [itemClicado, setItemClicado] = useState(0);
  const itens = ['Informações do(a) Atendente', 'Dados da Empresa', 'Sobre Empresa', 'Preços', 'Escopo das Atividades da Empresa', 'Limitações das Atividades da Empresa'];
  const [nomeAtendente, setNomeAtendente] = useState("");
  const [nomeAtendenteError, setNomeAtendenteError] = useState('');
  const [personalidadeAtendente, setPersonalidadeAtendente] = useState("");
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [ramoEmpresa, setRamoEmpresa] = useState("");
  const [telefone, setTelefone] = useState("");
  const [endereco, setEndereco] = useState("");
  const [site, setSite] = useState("");
  const [email, setEmail] = useState("");
  const [horarioFuncionamento, setHorarioFuncionamento] = useState("");
  const [produtoServico, setProdutoServico] = useState("");
  const [objetivomissao, setObjetivoMissao] = useState("");
  const [preco, setPreco] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [escopoAtividades, setEscopoAtividades] = useState("");
  const [limitacoesAtividades, setLimitacoesAtividades] = useState("");
  const [resultado, setResultado] = useState("");
  const [numeroCelular, setNumeroCelular] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    setIsLoading(true);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    if (!isLoading) {
      setShowModal(false);
    }
  };

  const handleItemClick = (index) => {
    setItemClicado(index);
  };

  const handleChangeCelular = (event) => {
    const formattedValue = event.target.value;
    setNumeroCelular(formattedValue);
  };

  useEffect(() => {
    const carregarDadosFirestore = async () => {

      const dadosRef = doc(db, "infoAtendente", sessao);

      try {
        const docSnapshot = await getDoc(dadosRef);

        if (!docSnapshot.exists()) {
          // O documento não existe, então vamos criar um novo documento com os preco iniciais
          await setDoc(dadosRef, {
            nomeAtendente: "",
            personalidadeAtendente: "",
            nomeEmpresa: "",
            ramoEmpresa: "",
            telefone: "",
            endereco: "",
            site: "",
            email: "",
            horarioFuncionamento: "",
            produtoServico: "",
            objetivomissao: "",
            preco: "",
            formaPagamento: "",
            escopoAtividades: "",
            limitacoesAtividades: "",
            resultado: "",
            numeroCelular
          });
        } else {
          const dados = docSnapshot.data();

          if (dados.nomeAtendente) setNomeAtendente(dados.nomeAtendente);
          if (dados.personalidadeAtendente) setPersonalidadeAtendente(dados.personalidadeAtendente);
          if (dados.nomeEmpresa) setNomeEmpresa(dados.nomeEmpresa);
          if (dados.ramoEmpresa) setRamoEmpresa(dados.ramoEmpresa);
          if (dados.telefone) setTelefone(dados.telefone);
          if (dados.endereco) setEndereco(dados.endereco);
          if (dados.site) setSite(dados.site);
          if (dados.email) setEmail(dados.email);
          if (dados.horarioFuncionamento) setHorarioFuncionamento(dados.horarioFuncionamento);
          if (dados.produtoServico) setProdutoServico(dados.produtoServico);
          if (dados.objetivomissao) setObjetivoMissao(dados.objetivomissao);
          if (dados.preco) setPreco(dados.preco);
          if (dados.formaPagamento) setFormaPagamento(dados.formaPagamento);
          if (dados.escopoAtividades) setEscopoAtividades(dados.escopoAtividades);
          if (dados.limitacoesAtividades) setLimitacoesAtividades(dados.limitacoesAtividades);
          if (dados.resultado) setResultado(dados.resultado);
          if (dados.numeroCelular) setNumeroCelular(dados.numeroCelular);
        }
      } catch (error) {
        console.error("Erro ao carregar os dados do Firestore:", error);
      }
    };

    carregarDadosFirestore();
  }, []);

  async function gerarPreco() {
    handleClick()
    try {
      const response = await axios.post('https://suporte.agteletronic.com.br/chat', {
        "message": `Oferecidos por uma ${ramoEmpresa}.`,
        "informacoes": "Gerador de preço de produtos/serviços de forma mais sintetizada possíve, *não colocar observações* e ao inves de númerar colocar hífens."
      });
      const respostaDoServidor = response.data.response.content;

      // Atualizar o estado com a resposta do servidor
      setPreco(respostaDoServidor);
      enqueueSnackbar('Exemplo gerado com sucesso!', { variant: 'success' });
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      // Trate qualquer erro de envio aqui
      console.error(error);
      // Ou exiba uma mensagem de erro ao usuário
      alert('Ocorreu um erro ao enviar os dados.');
    }
  }

  async function gerarProdutosServicos() {
    handleClick()
    try {
      const response = await axios.post('https://suporte.agteletronic.com.br/chat', {
        "message": `Por favor, preciso de uma descrição de produtos ou serviços oferecidos por uma ${ramoEmpresa}, de forma sintetizada.`,
        "informacoes": " Gerador de textos para minha empresa *não colocar observações*."
      });
      const respostaDoServidor = response.data.response.content;

      // Atualizar o estado com a resposta do servidor
      setProdutoServico(respostaDoServidor);
      enqueueSnackbar('Exemplo gerado com sucesso!', { variant: 'success' });
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      // Trate qualquer erro de envio aqui
      console.error(error);
      // Ou exiba uma mensagem de erro ao usuário
      alert('Ocorreu um erro ao enviar os dados.');
    }
  }

  async function gerarObjetivoMissao() {
    handleClick()
    try {
      const response = await axios.post('https://suporte.agteletronic.com.br/chat', {
        "message": `Por favor, para uma ${ramoEmpresa}. de forma sintetizada.`,
        "informacoes": "Gerador de de um  objetivo / missão, *não colocar observações*."
      });
      const respostaDoServidor = response.data.response.content;

      // Atualizar o estado com a resposta do servidor
      setObjetivoMissao(respostaDoServidor);
      enqueueSnackbar('Exemplo gerado com sucesso!', { variant: 'success' });
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      // Trate qualquer erro de envio aqui
      console.error(error);
      // Ou exiba uma mensagem de erro ao usuário
      alert('Ocorreu um erro ao enviar os dados.');
    }
  }

  async function gerarEscopo() {
    handleClick()
    try {
      const response = await axios.post('https://suporte.agteletronic.com.br/chat', {
        "message": `Crie 10 tópicos não numerados com descrições em forma de passo a passo de procedimentos ficticios que os clientes realizam em um(a) ${ramoEmpresa}, com descrições sintetizadas e tópicos em negrito. Por Favor.-++++++++++++y`,
        "informacoes": "Gerador de texto com tópicos e descrição, *não colocar observações*"
      });
      const respostaDoServidor = response.data.response.content;

      // Atualizar o estado com a resposta do servidor
      setEscopoAtividades(respostaDoServidor);
      enqueueSnackbar('Exemplo gerado com sucesso!', { variant: 'success' });
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      // Trate qualquer erro de envio aqui
      console.error(error);
      // Ou exiba uma mensagem de erro ao usuário
      alert('Ocorreu um erro ao enviar os dados.');
    }
  }

  async function gerarLimitacoes() {
    handleClick()
    try {
      const response = await axios.post('https://suporte.agteletronic.com.br/chat', {
        "message": `10 limitações temporárias que os clientes não podem realizar em um(a) ${ramoEmpresa}.`,
        "informacoes": "Gerador de texto, apenas tópicos, *não colocar observações*"
      });
      const respostaDoServidor = response.data.response.content;

      // Atualizar o estado com a resposta do servidor
      setLimitacoesAtividades(respostaDoServidor);
      enqueueSnackbar('Exemplo gerado com sucesso!', { variant: 'success' });
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      // Trate qualquer erro de envio aqui
      console.error(error);
      // Ou exiba uma mensagem de erro ao usuário
      alert('Ocorreu um erro ao enviar os dados.');
    }
  }


  async function formatarEscopo(texto) {
    handleClick()
    try {
      const response = await axios.post('https://suporte.agteletronic.com.br/chat', {
        "message": texto,
        "informacoes": "Por favor, formate o texto por categoria criando tópicos em negrito **"
      });
      const respostaDoServidor = response.data.response.content;

      // Atualizar o estado com a resposta do servidor
      setEscopoAtividades(respostaDoServidor);
      enqueueSnackbar('Texto formatado com sucesso!', { variant: 'success' });
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      // Trate qualquer erro de envio aqui
      console.error(error);
      // Ou exiba uma mensagem de erro ao usuário
      alert('Ocorreu um erro ao enviar os dados.');
    }
  }

  async function formatarLimitacoes(texto) {
    handleClick()
    try {
      const response = await axios.post('https://suporte.agteletronic.com.br/chat', {
        "message": texto,
        "informacoes": "Por favor, formate o texto acrecentando ifens e negrito caso tenha tópicos."
      });
      const respostaDoServidor = response.data.response.content;

      // Atualizar o estado com a resposta do servidor
      setLimitacoesAtividades(respostaDoServidor);
      enqueueSnackbar('Texto formatado com sucesso!', { variant: 'success' });
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      // Trate qualquer erro de envio aqui
      console.error(error);
      // Ou exiba uma mensagem de erro ao usuário
      alert('Ocorreu um erro ao enviar os dados.');
    }
  }

  async function sintetizarEscopo(texto) {
    handleClick()
    try {
      const response = await axios.post('https://suporte.agteletronic.com.br/chat', {
        "message": texto,
        "informacoes": "Por favor, sintetize as informações, para diminuir o texto."
      });
      const respostaDoServidor = response.data.response.content;

      // Atualizar o estado com a resposta do servidor
      setEscopoAtividades(respostaDoServidor);
      enqueueSnackbar('Texto sintetizado com sucesso!', { variant: 'success' });
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      // Trate qualquer erro de envio aqui
      console.error(error);
      // Ou exiba uma mensagem de erro ao usuário
      alert('Ocorreu um erro ao enviar os dados.');
    }
  }

  async function sintetizarLimitacoes(texto) {
    handleClick()
    try {
      const response = await axios.post('https://suporte.agteletronic.com.br/chat', {
        "message": texto,
        "informacoes": "Por favor, sintetize as informações, para diminuir o texto."
      });
      const respostaDoServidor = response.data.response.content;

      // Atualizar o estado com a resposta do servidor
      setLimitacoesAtividades(respostaDoServidor);
      enqueueSnackbar('Texto sintetizado com sucesso!', { variant: 'success' });
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      // Trate qualquer erro de envio aqui
      console.error(error);
      // Ou exiba uma mensagem de erro ao usuário
      alert('Ocorreu um erro ao enviar os dados.');
    }
  }

  const handleAtualizarDados = async () => {
    const db = getFirestore();
    const dadosRef = doc(db, "infoAtendente", sessao);

    try {
      await updateDoc(dadosRef, {
        nomeAtendente,
        personalidadeAtendente,
        nomeEmpresa,
        ramoEmpresa,
        telefone,
        endereco,
        site,
        email,
        horarioFuncionamento,
        produtoServico,
        objetivomissao,
        preco,
        formaPagamento,
        escopoAtividades,
        limitacoesAtividades,
        resultado,
        numeroCelular
      });

      enqueueSnackbar('Dados atualizados com sucesso!', { variant: 'success' });
    } catch (error) {
      console.error('Erro ao atualizar os dados no Firestore:', error);
      enqueueSnackbar('Erro ao atualizar os dados.', { variant: 'error' });
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    // Realize a validação manual do campo 'nomeAtendente' aqui
    if (!nomeAtendente) {
      setNomeAtendenteError('Campo obrigatório');
    } else {
      setNomeAtendenteError('');

      // O restante da lógica de envio do formulário pode ser inserido aqui
      console.log('Nome do atendente:', nomeAtendente);
    }

    // Array para armazenar as informações preenchidas
    const informacoes = [];

    if (nomeEmpresa) informacoes.push(`**Atendimento virtual - ${nomeEmpresa}**`);
    if (nomeAtendente) informacoes.push(`\nOlá! Sou ${nomeAtendente}, seu atendente virtual da ${nomeEmpresa}. E minha personalidade é ${personalidadeAtendente}. Estou aqui para ajudar você. Abaixo estão as informações importantes:`);
    if (ramoEmpresa) informacoes.push(`\n**Ramo de Atividade:**\n- ${ramoEmpresa}`);
    informacoes.push(`\n**Contato:**`);
    if (telefone) informacoes.push(`- Telefone: ${telefone}`);
    if (endereco) informacoes.push(`- Endereço: ${endereco}`);
    if (site) informacoes.push(`- Website: ${site}`);
    if (email) informacoes.push(`- Email de contato: ${email}`);
    if (horarioFuncionamento) informacoes.push(`\n**Horário de Funcionamento:**\n${horarioFuncionamento}`);
    if (produtoServico) informacoes.push(`\n **Produtos/Serviços:**\n${produtoServico}`);
    if (objetivomissao) informacoes.push(`\n **Objetivo / Missão:**\n${objetivomissao}`);
    if (preco) informacoes.push(`\n**Preços:**\n${preco}`);
    if (formaPagamento) informacoes.push(`\n**Formas de pagamento:**\n${formaPagamento}`);
    if (escopoAtividades) informacoes.push(`\n**Detalhes das Atividades:**\n${escopoAtividades}`);
    if (limitacoesAtividades) informacoes.push(`\n**Limitações de Atividades:**\n${limitacoesAtividades}`);
    informacoes.push("\n**Transferir para atendimento humano:**\n- Caso a pessoa desejar transferência de atendimento responda apenas com está mensagem: [Claro! Vou encaminhar seu contato para um atendente humano. Por favor, aguarde um momento enquanto ele entra em contato com você. Obrigado.].\n");

    // Concatena as informações preenchidas em uma única string
    const informacoesConcatenadas = informacoes.join("\n");
    // Define o estado "resultado" como a string concatenada
    setResultado(informacoesConcatenadas);
    setTimeout(() => {
      handleAtualizarDados();
    }, 3000); // 3000 milissegundos (3 segundos)
  };


  return (
    <div className="list row">
      <div className="col-md-3">
        {/* Coluna menor */}
        <div className="col-md-12">
          {/* Lista de botões */}
          <ul className="list-group">
            {itens.map((item, index) => (
              <li
                key={index}
                className={`list-group-item ${index === itemClicado ? 'active' : ''}`}
                onClick={() => handleItemClick(index)}
              >
                {item}
              </li>

            ))}
          </ul>
        </div>
      </div>

      <div className="col-md-9">
        {/* Exibe o formulário atual */}
        {itemClicado === 0 && (
          <Container>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formNomeAtendente">
                <Form.Label>Nome do(a) atendente virtual</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o nome da atendente virtual"
                  value={nomeAtendente}
                  onChange={(event) => {
                    setNomeAtendente(event.target.value);
                    setNomeAtendenteError(''); // Limpa o erro quando o campo é alterado
                  }}
                />
                {nomeAtendenteError && (
                  <div className="error">{nomeAtendenteError}</div>
                )}
              </Form.Group>
              <Form.Group controlId="formPersonalidadeAtendente">
                <Form.Label>Personalidade</Form.Label>
                <Form.Select
                  value={personalidadeAtendente}
                  onChange={(event) => setPersonalidadeAtendente(event.target.value)}
                >
                  <option value="">Selecione aqui a personalidade</option>
                  <option value="Formal">Formal: Uso de linguagem precisa e estruturada, com um tom respeitoso.</option>
                  <option value="Sério">Sério: Tom sóbrio, sem humor ou descontração.</option>
                  <option value="Impessoal">Impessoal: Abordagem objetiva, sem expressão de emoções ou opiniões pessoais.</option>
                  <option value="Empático">Empático: Demonstração de compreensão e compartilhamento das emoções do leitor.</option>
                  <option value="Didático">Didático: Orientado para ensinar e fornecer informações de maneira clara.</option>
                  <option value="Crítico">Crítico: Enfatiza avaliação e julgamento.</option>
                  <option value="Cínico">Cínico: Descrença ou desconfiança das motivações ou ações das pessoas.</option>
                  <option value="Otimista">Otimista: Tendência a esperar o melhor resultado possível.</option>
                  <option value="Pessimista">Pessimista: Tendência a esperar o pior resultado.</option>
                  <option value="Casual">Casual: Linguagem e tom descontraídos, sem formalidade.</option>
                  <option value="Humorístico">Humorístico: Incorpora humor, piadas e ironia.</option>
                  <option value="Animado">Animado: Cheio de energia e entusiasmo.</option>
                  <option value="Reservado">Reservado: Abordagem cautelosa, sem expressão excessiva de emoções.</option>
                  <option value="Enérgico">Enérgico: Linguagem cheia de vigor, paixão e emoção.</option>
                  <option value="Agressivo">Agressivo: Confronto direto, linguagem de comando.</option>
                  <option value="Passivo">Passivo: Abordagem suave, sem confronto.</option>
                  <option value="Encorajador">Encorajador: Orientado para inspirar e motivar o leitor.</option>
                  <option value="Curioso">Curioso: Mostra um desejo de aprender ou saber mais.</option>
                  <option value="Afetuoso">Afetuoso: Demonstração de calor e carinho.</option>
                  <option value="Apático">Apático: Mostra indiferença e falta de interesse.</option>
                </Form.Select>
              </Form.Group>
              <Button variant="primary btn-sm" type="submit">
                Salvar
              </Button>
            </Form>

          </Container>
        )}

        {itemClicado === 1 && (
          <Container>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formNomeEmpresa">
                <Form.Label>Nome da empresa</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o nome da empresa"
                  value={nomeEmpresa}
                  onChange={(event) => setNomeEmpresa(event.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formRamoEmpresa">
                <Form.Label>Tipo de estabelecimento</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Exemplo: Hotel, Pousada, Farmácia, Restaurante, Escritório de Advocacia."
                  value={ramoEmpresa}
                  onChange={(event) => setRamoEmpresa(event.target.value)}
                />

              </Form.Group>
              <Form.Group controlId="formTelefone">
                <Form.Label>Telefone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o telefone"
                  value={telefone}
                  onChange={(event) => setTelefone(event.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formNumeroCelular">
                <Form.Label>WhatsApp para transferir atendimento</Form.Label>
                <div>
                  <Form.Control
                    type="text"
                    placeholder="Digite um número de WhatsApp para fazer transferencia de atendimento"
                    value={numeroCelular}
                    onChange={handleChangeCelular}
                  />
                  <ModalGenerico informacao={'Neste espaço, você pode inserir o número de telefone do WhatsApp para receber notificações sempre que um cliente desejar conversar com um atendente humano. Não se esqueça de adicionar o código do país à frente do número, no caso do Brasil, você deve incluir "+55" seguido do DDD e, em seguida, o número de telefone, tudo junto sem espaçoes, parenteses ou hífens. Exemplo +5511012345678'} />
                </div>
              </Form.Group>

              <Form.Group controlId="formEndereco">
                <Form.Label>Endereço</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o endereço do estabelecimento"
                  value={endereco}
                  onChange={(event) => setEndereco(event.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formSite">
                <Form.Label>Site</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o site"
                  value={site}
                  onChange={(event) => setSite(event.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Digite o e-mail"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formHorarioFuncionamento">
                <Form.Label>Horário de funcionamento</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o horário de funcionamento"
                  value={horarioFuncionamento}
                  onChange={(event) => setHorarioFuncionamento(event.target.value)}
                />
              </Form.Group>
              <Button variant="primary btn-sm" type="submit">
                Salvar
              </Button>
            </Form>

          </Container>
        )}

        {itemClicado === 2 && (
          <Container>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formProdutoServico">
                <Form.Label>Produto/Serviço</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Descreva em forma de textos os produtos e serviços oferecidos por sua empresa."
                  value={produtoServico}
                  onChange={(event) => setProdutoServico(event.target.value)}
                  rows={8}
                />
                {ramoEmpresa !== "" && produtoServico === "" && (
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={gerarProdutosServicos}
                  >
                    Quero Exemplo
                  </Button>
                )}
              </Form.Group>

              <Form.Group controlId="formpreco">
                <Form.Label>Objetivo e Missão:</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Digite o Objetivo e Missão da sua empresa."
                  value={objetivomissao}
                  onChange={(event) => setObjetivoMissao(event.target.value)}
                  rows={5} // Defina o número de linhas visíveis, por exemplo, 3
                />
                {ramoEmpresa !== "" && objetivomissao === "" && (
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={gerarObjetivoMissao}
                  >
                    Quero Exemplo
                  </Button>
                )}
              </Form.Group>
              <Button variant="primary btn-sm" type="submit">
                Salvar
              </Button>
            </Form>

          </Container>
        )}

        {itemClicado === 3 && (
          <Container>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formpreco">
                <Form.Label>Preços:</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Digite os preços"
                  value={preco}
                  onChange={(event) => setPreco(event.target.value)}
                  rows={6} // Defina o número de linhas visíveis, por exemplo, 3
                />
                {ramoEmpresa !== "" && preco === "" && (
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={gerarPreco}
                  >
                    Quero Exemplo
                  </Button>
                )}
              </Form.Group>

              <Form.Group controlId="formFormaPagamento">
                <Form.Label>Formas de pagamento</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite a forma de pagamento"
                  value={formaPagamento}
                  onChange={(event) => setFormaPagamento(event.target.value)}
                />
              </Form.Group>
              <Button variant="primary btn-sm" type="submit">
                Salvar
              </Button>
            </Form>

          </Container>
        )}

        {itemClicado === 4 && (
          <Container>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formEscopoAtividades">
                <Form.Label>Escopo de Atividades</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    as="textarea"
                    placeholder="Por gentileza, forneça um guia detalhado sobre como acessar e aproveitar os serviços oferecidos por sua empresa. Se desejar, você pode organizar as informações em tópicos para uma compreensão mais clara. Caso surjam dúvidas durante o processo, sinta-se à vontade para clicar na opção 'Quero Exemplo' para obter assistência adicional."
                    value={escopoAtividades}
                    onChange={(event) => setEscopoAtividades(event.target.value)}
                    rows={18}
                  />

                </div>
              </Form.Group>
              {ramoEmpresa !== "" && escopoAtividades === "" && (
                <Button
                  variant="warning"
                  size="sm"
                  onClick={gerarEscopo}
                >
                  Quero Exemplo
                </Button>
              )}
              {escopoAtividades !== "" && (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => formatarEscopo(escopoAtividades)}
                >
                  Formatar texto
                </Button>
              )}
              {escopoAtividades !== "" && (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => sintetizarEscopo(escopoAtividades)}
                >
                  Sintetizar texto
                </Button>
              )}
              <Button variant="primary btn-sm" type="submit">
                Salvar
              </Button>
            </Form>

          </Container>
        )}

        {itemClicado === 5 && (
          <Container>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formLimitacoesAtividades">
                <Form.Label>Limitações de Atividades</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Por gentileza, forneça informações de serviços/produtos que sua empresa não fornece/trabalha. Caso surjam dúvidas durante o processo, sinta-se à vontade para clicar na opção 'Quero Exemplo' para obter assistência adicional."
                  value={limitacoesAtividades}
                  onChange={(event) => setLimitacoesAtividades(event.target.value)}
                  rows={18} // Defina o número de linhas visíveis, por exemplo, 3
                />
              </Form.Group>
              {ramoEmpresa !== "" && limitacoesAtividades === "" && (
                <Button
                  variant="warning"
                  size="sm"
                  onClick={() => gerarLimitacoes()}
                >
                  Quero Exemplo
                </Button>
              )}
              {limitacoesAtividades !== "" && (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => formatarLimitacoes(limitacoesAtividades)}
                >
                  Formatar texto
                </Button>
              )}
              {limitacoesAtividades !== "" && (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => sintetizarLimitacoes(limitacoesAtividades)}
                >
                  Sintetizar texto
                </Button>
              )}
              <Button variant="primary btn-sm" type="submit">
                Salvar
              </Button>
            </Form>

          </Container>
        )}

        {itemClicado === 6 && (
          <Container>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formLimitacoesAtividades">
                <Form.Label>Resultado</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Resultado"
                  value={resultado}
                  onChange={(event) => setResultado(event.target.value)}
                  rows={18} // Defina o número de linhas visíveis, por exemplo, 3
                />
              </Form.Group>
            </Form>

          </Container>
        )}
        <div>
          <Modal className="special_modal" show={showModal} onHide={handleCloseModal} backdrop="static">
            <Modal.Header className="modal-header-atendente">
              <Modal.Title className="modal-title">
                <div className="avatar-container">
                  <Avatar src={cyborgImage} alt="Ariel" className={classes.avatar} />
                  <div>
                    <div className="text-right text-white font-stylish">Ariel - Atendente Virtual</div>
                    <div className="text-right text-white font-stylish">Online</div>
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isLoading ? (
                <Spinner animation="grow" variant="primary" />
              ) : (
                'Resposta recebida!'
              )}
              <br />
              Prezado usuário,<br /><br />
              Estamos preparando o texto que você pediu e logo você receberá. Depois de recebê-lo, leia e faça as mudanças que precisar. Lembre-se de salvar as alterações.<br /><br />
              Agradecemos por escolher nossos serviços.<br /><br />
              Atenciosamente,<br /><br />
              Ariel
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div >

  );
};

export default function IntegrationNotistack(id) {
  return (
    <SnackbarProvider maxSnack={3}>
      <Formulario id={id} />
    </SnackbarProvider>
  );
}