import React from "react";
import { Card, Row } from "antd";
import SessoesModal from "./Sessoes/Modal";
import SessoesRoboModal from "./Sessoes_Robo/Modal";
import ListadeResgistrosVideos from "./VideoTutorial/listaderegistros";
import ChatSimples from "./ChatSimples/modal";

const Listas = (currentUser) => {
  return (
    <div>
      <Card title="Serviços">
        <div className="list row">
          <ul className="list-group">
            <div className="center">
              <Row>
                <SessoesModal usuario={currentUser} />
                <SessoesRoboModal usuario={currentUser} />
                
              </Row>
            </div>
          </ul>
        </div>
      </Card>
      <br />
      <Card title="Central de Ajuda">

            <ListadeResgistrosVideos usuario={currentUser} />
            
      </Card>
      <ChatSimples usuario={currentUser} />
      <br />
    </div>
  );

};



export default Listas;
