import React, { useState, useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import Paper from "@material-ui/core/Paper";
import Registro from "./registro";
import { db } from "../../../firebase";
import { collection, where, query } from "firebase/firestore";

const colecao = "VideoTutoriais";

const ListadeResgistrosVideos = (currentUser) => {
  const [registroAtual, setRegistroAtual] = useState(null);
  const [indiceAtual, setIndiceAtual] = useState(-1);
  const [registros, loading, error] = useCollection(
    query(
      collection(db, colecao),
      where("uid", "==", currentUser.usuario.usuario.uid)
    )
  );

  useEffect(() => {
    if (!loading && registros && registros.docs.length > 0 && indiceAtual === -1) {
      // Verifica se a lista de registros não está vazia e se o índice atual ainda não foi definido
      setRegistroAtivo(registros.docs[0], 0); // Define o primeiro registro como ativo
    }
  }, [loading, registros, indiceAtual]);

  const refreshList = () => {
    setRegistroAtual(null);
    setIndiceAtual(-1);
  };

  const setRegistroAtivo = (registro, index) => {
    const { uid, criado, apagar, titulo, link } = registro.data();

    setRegistroAtual({
      id: registro.id,
      uid,
      criado,
      apagar,
      titulo,
      link,
    });

    setIndiceAtual(index);
  };

  return (
    <div className="list row">
      <div className="col-md-4">
        {error && <strong>Erro: {error}</strong>}
        {loading && <span>Carregando...</span>}
        <ul className="list-group">
          <Paper
            style={{
              maxHeight: 300,
              overflow: "auto",
              backgroundColor: "#1e2129",
              color: "#98a0b4",
            }}
          >
            {!loading &&
              registros &&
              registros.docs.map((registro, index) => (
                <i key={registro.id}>
                  <li
                    className={
                      "list-group-item " + (index === indiceAtual ? "active" : "")
                    }
                    onClick={() => setRegistroAtivo(registro, index)}
                  >
                    {registro.data().titulo}
                  </li>
                </i>
              ))}
          </Paper>
        </ul>
      </div>

      <div className="col-md-8">
        {registroAtual ? (
          <Registro registro={registroAtual} refreshList={refreshList} />
        ) : (
          <div>
            <br />
            <p> &lt; Selecione um vídeo...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListadeResgistrosVideos;
