import React, { useState, useEffect } from "react";
import YouTubePlayer from "./YouTubePlayer";

const Registro = (props) => {
  const estadoInicial = {
    key: null,
    uid: "",
    criado: "",
    apagar: "",
    titulo: "",
    link: "",
  };

  const [regtemp, setRegtemp] = useState(estadoInicial);
  const [mensagem, setMensagem] = useState("");
  const [playerKey, setPlayerKey] = useState(null);
  const { registro } = props;

  useEffect(() => {
    if (registro.id !== regtemp.id) {
      setRegtemp(registro);
      setPlayerKey(Date.now()); // Gera uma nova chave para atualizar o player
      setMensagem("");
    }
  }, [registro, regtemp.id]);

  return (
    <div>
      {regtemp ? (
        <div className="edit-form">
          <form>
            <div>
              <YouTubePlayer key={playerKey} videoId={regtemp.link} />
            </div>
          </form>
        </div>
      ) : (
        <div>
          <br />
          <p> &lt;---------- Clique no Registro...</p>
        </div>
      )}
    </div>
  );
};

export default Registro;
