import React, { useState } from "react";
import ListadeResgistros from '.';
import { Modal, Button } from "react-bootstrap";
import GerarModal from "../GerarSessao/modal"
import { Card } from "antd";
import Tooltip from "@material-ui/core/Tooltip";
import ModalGenerico from "../modalGenerico/modal";

const { Meta } = Card;

export default function SessoesRoboModal(currentUser) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Card
        onClick={handleShow}
        hoverable
        style={{
          width: 170,
          marginleft: 'auto',
          marginright: 'auto',
        }}
        cover={
          <div className="center2">
            <img
              src="/cyborg.png" // Substitua pelo caminho ou URL da sua imagem
              alt="Ariel - WhatsApp"
              style={{ width: '60%', height: 'auto' }} // Ajuste o estilo conforme necessário
            />
          </div>
        }
      >
        <Meta
          title="WhatsApp"
          description="Atendente Virtual para WhatsApp"
          onClick={handleShow}
        />
      </Card >
      <Modal className="special_modal" size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Sessões Atendente Virtual
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListadeResgistros usuario={currentUser} />
        </Modal.Body>
        <Modal.Footer>
          <ModalGenerico informacao ={"As sessões são os espaços dedicados onde seus chatbots residem. Cada sessão hospeda as configurações do seu assistente virtual, concedendo a você a flexibilidade de personalizar as informações da sua empresa e estabelecer os horários de operação do seu chatbot. Você tem a capacidade de criar sessões distintas para atender diferentes empresas ou setores dentro da mesma organização, garantindo uma gestão eficaz e adaptada às necessidades específicas de cada contexto."}/>
          <GerarModal tipo={"robo"} />
          <Tooltip title="Fechar">
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
