import React, { useState, useEffect } from "react";
import { doc, setDoc } from "firebase/firestore";
import axios from 'axios';
import { db } from "../../../../firebase";
import Erro from "../../Erro/erro";
import { Form, Button } from 'react-bootstrap';

export default function QR(registro) {

  const [regtemp, setRegtemp] = useState({
    qrcode: "",
    pixCopiaECola: "",
  });
  const registro_temp = registro.registro.registro

  const data = JSON.stringify({
    "valor": registro_temp.valor,
  });

  const config = {
    method: 'post',
    url: 'https://agteletronic.com.br/gerarpix/gerarCobranca',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  useEffect(() => {
    gerar();
  }, []);

  const gerar = () => {

    axios(config)
      .then(function (response) {
        const pixCopiaEColaCompleta = response.data.pixCopiaECola;
        const pixCopiaEColaTruncada = pixCopiaEColaCompleta.substring(0, 35); // Truncar para 40 caracteres
        setRegtemp({
          qrcode: response.data.qrcode,
          pixCopiaECola: pixCopiaEColaTruncada,
          pixCopiaEColaCompleta: pixCopiaEColaCompleta
        })

        const gravar = {
          criado: registro_temp.criado,
          expiracao: registro_temp.expiracao,
          estado: registro_temp.estado,
          id_secao: registro_temp.id_secao,
          nomesecao: registro_temp.nomesecao,
          qrcode: "",
          txid: response.data.txid,
          uid: registro_temp.uid,
          valor: registro_temp.valor,
          validade: registro_temp.validade
        }

        const docRef = doc(db, "faturas", registro_temp.id);
        setDoc(docRef, gravar)
          .then(docRef => {

          })
          .catch(error => {
            alert('Desculpe-nos, mas estamos fazendo uma manutenção temporária em nosso site. Por favor, tente novamente mais tarde. Obrigado pela sua compreensão.');
            Erro("Erro ao gerar fatura.")
          })
      })
      .catch(function (error) {
        alert('Desculpe-nos, mas estamos fazendo uma manutenção temporária em nosso site. Por favor, tente novamente mais tarde. Obrigado pela sua compreensão.');
        Erro("Erro ao gerar fatura.")
      });
  }

  const handleCopyPix = () => {
    // Copiar o valor Pix completo para a área de transferência
    navigator.clipboard.writeText(regtemp.pixCopiaEColaCompleta)
      .then(() => alert('Chave Pix copiada com sucesso!'))
      .catch(err => console.error('Erro ao copiar a chave Pix:', err));
  }

  return (
    <div className="container">
      <div className="col-md-12">
        <div className="panel panel-primary">
          <div className="panel-body">
            {regtemp.qrcode === "" ? (
              <div><i className="fa fa-spinner fa-pulse fa-5x fa-fw"></i> Carregando...</div>
            ) : (
              <div>
                <p><strong>Aponte a câmera do seu aplicativo de pagamentos para o QR Code.</strong></p>
                <img src={regtemp.qrcode} alt="QR Code" />
                {/* Formulário para exibir e copiar a chave Pix completa */}
                <Form>
                  <Form.Group controlId="formPixCompleta" style={{marginTop: "20px", marginBottom: "5px"}}>
                    <Form.Label><strong>Chave Pix:</strong></Form.Label>
                    <Form.Control type="text" value={regtemp.pixCopiaEColaCompleta} readOnly />
                  </Form.Group>
                  <Button style={{ margintTop: "10px" }} onClick={() => handleCopyPix(regtemp.pixCopiaEColaCompleta)}>Copia e Cola</Button>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};