import React, { useState } from "react";
import { FormControl } from 'react-bootstrap';
import { SnackbarProvider, useSnackbar } from "notistack";
import axios from 'axios';
import Erro from "../Erro/erro";

function MensagemEmMassa(registro) {
    const [numeros, setNumeros] = useState("");
    const [mensagem, setMensagem] = useState("");
    const [enviando, setEnviando] = useState(false);
    const [delay, setDelay] = useState(3); // atraso de 2 segundos
    const [mensagemVazia, setMensagemVazia] = useState(false);
    const [numerosVazio, setNumerosVazio] = useState(false);

    const handleNumerosChange = (event) => {
        // Removendo espaços em branco e números repetidos
        const numerosSemEspacos = event.target.value.replace(/[^\d,]/g, "");
        const numerosUnicos = [...new Set(numerosSemEspacos.split(","))];
        const numerosUnicosString = numerosUnicos.join(",");
        setNumeros(numerosUnicosString);
        setNumerosVazio(false)
    };

    const handleMensagemChange = (event) => {
        setMensagem(event.target.value);
        setMensagemVazia(false)
    };

    const { enqueueSnackbar } = useSnackbar();

    const handleClick = (dados, variant) => {
        enqueueSnackbar(dados, { variant });
    };

    const handleEnviarClick = () => {

        if (numeros === "") {
            setNumerosVazio(true);
            return;
        }
        if (mensagem === "") {
            setMensagemVazia(true);
            return;
        }
        setEnviando(true);
        let numerosArray = normalizeNumeros(numeros).split(",");
        numerosArray = numerosArray.map((numero) => numero.trim());
        if (numerosArray[numerosArray.length - 1] === "") {
            numerosArray = numerosArray.slice(0, -1);
        }
        numerosArray = [...new Set(numerosArray)]; // remover números duplicados
        let contador = 0;
        
        const intervalId = setInterval(() => {
            
            if (contador < numerosArray.length) {
                //handleFormSubmit(numerosArray[contador])
                console.log(contador)
                handleClick(`Enviando mensagem para ${numerosArray[contador]}`, "success");
                contador++;
            } else {
                setEnviando(false);
                clearInterval(intervalId);
                alert(`Envio em massa concluído! Mensagem enviada para ${numerosArray.length} número(s) de telefone.`);
            }
        }, delay * 1000);
    };

    const normalizeNumeros = (numeros) => {
        // Remove espaços entre os números
        numeros = numeros.replace(/\s/g, "");
        // Remove vírgulas extras
        numeros = numeros.replace(/,{2,}/g, ",");
        // Remove vírgula no final
        numeros = numeros.replace(/,$/, "");
        return numeros;
    };

    const handleFormSubmit = (numero) => {
        var regtemp = {
            number: "55" + numero,
            message: mensagem,
            id: registro.id.id.id_secao,
            token: registro.id.id.token
        }

        axios({
            method: 'POST',
            url: 'https://api.agteletronic.com.br/send-message',
            data: regtemp
        })
            .then(function (response) {

            })
            .catch(function (response) {
                alert('Desculpe-nos, mas estamos fazendo uma manutenção temporária em nosso site. Por favor, tente novamente mais tarde. Obrigado pela sua compreensão.');
                Erro("Erro ao enviar mensagem no site.")
            });
    }

    return (

        <div>

            <div className="form-group">
                <label>
                    <span>{numeros === "" ? <div>Quantidade: 0</div> : <div>Quantidade: {numeros.split(",").length}</div>} </span>
                </label>
                <br />
                <label>
                    Números de telefone (ddd + número, separados por vírgula):
                </label>
                <FormControl
                    as="textarea"
                    placeholder="números..."
                    id="números"
                    style={{ height: '100px' }}
                    required
                    value={numeros}
                    onChange={handleNumerosChange}
                    name="números" />
                {numerosVazio && <span style={{ color: "red" }}>O número não pode estar vazio!</span>}
            </div>
            <label>
                Mensagem:
            </label>
            <FormControl
                as="textarea"
                placeholder="mensagem..."
                id="mensagem"
                style={{ height: '100px' }}
                required
                value={mensagem}
                onChange={handleMensagemChange}
                name="números" />
            {mensagemVazia && <span style={{ color: "red" }}>A mensagem não pode estar vazia!</span>}
            <label>
                Intervalo (segundos):
                <FormControl
                    type="number"
                    min="3"
                    value={delay}
                    onChange={(e) => setDelay(e.target.value)} />
            </label>
            <br />
            <button disabled={enviando} onClick={handleEnviarClick} className="btn btn-info btn-sm pull-right">
                Enviar
            </button>
            <br />
            <div />

        </div>

    );
}


export default function IntegrationNotistack(registro) {
    return (
        <SnackbarProvider maxSnack={3}>
            <MensagemEmMassa id={registro} />
        </SnackbarProvider>
    );
}